// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Header
\* ------------------------------------------------------------------------ */

.header-block {
    & {
        @include layer("header");
        left: 0;
        padding: remify(12.5 0);
        position: absolute;
        right: 0;
        top: remify(25);
    }

    &.-fullbleed {
        background: $dark; // non rgba fallback
        background: transparentize($dark, 0.2);
        border-bottom: remify(3) solid $accent_alt;
    }

    &.-fullbleed:after {
        background: $accent;
        content: "\0020";
        display: block;
        height: remify(8);
        left: 0;
        margin-top: remify(3);
        position: absolute;
        right: 0;
        top: 100%;
    }
}

.header_inner {
    & {
        background: $dark; // non rgba fallback
        background: transparentize($dark, 0.2);
        border-bottom: remify(3) solid $accent_alt;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    &:after {
        background: $accent;
        content: "\0020";
        display: block;
        height: remify(8);
        left: 0;
        margin-top: remify(3);
        position: absolute;
        right: 0;
        top: 100%;
    }

    .header-block.-fullbleed > & {
        background: none;
        border-bottom: 0;
    }

    .header-block.-fullbleed > &:after {
        display: none;
    }
}

.header_logo {
    & {
        max-width: remify(240);
        padding: remify(10 25);
    }
}

.header_menu-toggle {
    & {
        display: block;
    }

    .menu-toggle_background {
        width: 125%;
    }
}

.header_search-form_container {
    & {
        width: 100%;
    }

    &.-collapsible .search-form {
        margin-bottom: remify(12.5, 16);
        width: auto;
    }

    &.-collapsible .search-form_button_background {
        width: 125%;
    }

    &.-collapsible .search-form_background {
        left: -12.5%;
        width: 125%;
    }
}

.header_menu-list_container {
    & {
        position: relative;
        z-index: 1;
    }

    .menu-list.-navigation {
        margin-bottom: remify(15, 18);
    }

    .menu-list.-navigation .menu-list_link {
        color: $light;
    }

    .menu-list.-navigation .menu-list_link:focus,
    .menu-list.-navigation .menu-list_link:hover,
    .menu-list.-navigation > .menu-list_item.-parent:hover > .menu-list_link,
    .menu-list.-navigation > .menu-list_item.-parent.is-active > .menu-list_link {
        color: $light_alt;
    }

    .menu-list.-navigation .menu-list_toggle {
        color: $light;
    }

    .menu-list.-navigation .menu-list_toggle:focus,
    .menu-list.-navigation .menu-list_toggle:hover,
    .menu-list.-navigation .menu-list_item.-parent:hover >.menu-list_toggle,
    .menu-list.-navigation .menu-list_item.-parent.is-active >.menu-list_toggle {
        color: $light_alt;
    }
}
