// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Page
\* ------------------------------------------------------------------------ */

html,
body {
    & {
        height: 100%;
        width: 100%;
    }
}

body {
    & {
        background: $page_background;
        overflow-x: hidden;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; // iOS momentum scrolling
    }
}

.page_container {
    & {
        min-height: 100%;
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}
