// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Menu Toggle
\* ------------------------------------------------------------------------ */

.menu-toggle {
    & {
        background: none;
        border: 0;
        color: $light;
        font-size: remify(16, 16);
        height: remify(32, 16);
        margin: remify(0 0 10, 16);
        padding: 0;
        position: relative;
        text-indent: -999em;
        width: remify(50, 16);
    }

    &:before,
    &:after {
        background: currentColor;
        bottom: 0;
        content: "\0020";
        display: block;
        height: remify(3, 16);
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        top: 0;
        transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
        width: remify(20, 16);
        z-index: 1;
    }

    &.-rounded:before,
    &.-rounded:after {
        border-radius: remify(1.5, 16);
    }

    &.is-active:before,
    &.is-active:after {
        transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s;
    }

    &:before {
        box-shadow: remify(0 6 0 0, 16) currentColor;
        top: remify(-12, 16);
    }

    &.is-active:before {
        box-shadow: 0 0 0 0 transparent;
        transform: rotate(45deg);
        top: 0;
    }

    &:after {
        top: remify(12, 16);
    }

    &.is-active:after {
        transform: rotate(-45deg);
        top: 0;
    }
}

.menu-toggle_background {
    & {
        background: $accent_alt;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: skewX(-17.5deg);
        width: 100%;
        z-index: 0;
    }
}

// search variant

.menu-toggle.-search {
    &:before {
        @include icon("search");
        background: none;
        bottom: 0;
        box-shadow: none;
        height: auto;
        left: 50%;
        right: auto;
        position: absolute;
        text-indent: 0;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
        width: auto;
    }

    &:after {
        display: none;
    }

    .menu-toggle_background {
        background: $accent;
    }
}
