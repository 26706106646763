// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Tooltip
\* ------------------------------------------------------------------------ */

.Zebra_Tooltip .Zebra_Tooltip_Message {
	& {
		font-family: $body-font;
	}
	
	a {
		color: $light;
		text-decoration: none;
		white-space: nowrap;
	}

}
