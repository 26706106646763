// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Footer
\* ------------------------------------------------------------------------ */

.footer-block {
    & {
        @include layer("footer");
        display: block;
    }

    &.-fullbleed {
        background: $background;
    }
}

.footer_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
        padding: remify(25);
    }

    .footer-block.-fullbleed > & {
        background: none;
    }
}

.footer_row {
    &.-padded {
        width: calc(100% + #{remify(20, 16)});
    }

    &.-padded > .col {
        padding-right: remify(20, 16);
    }

    .footer_inner > & > .col:not(:last-child) {
        margin-bottom: remify(20, 16);
    }

    .footer_inner > & > .col:last-child{
        text-align: center;
    }
}

.footer_link {
    & {
        color: $dark;
    }

    &:focus,
    &:hover {
        color: lighten($dark, 10);
    }
}

.footer_text {
    & {
        font-size: remify(10, 16);
    }
}

.footer_logo {
    & {
        width: remify(120, 16);
    }
}
