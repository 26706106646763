// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */

// Vendor

@import "../../vendor/_swiper";

// Custom

.swiper-slide {
    & {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: auto;
        overflow: hidden;
    }
}

.swiper-picture,
.swiper-picture {
    & {
        flex: none;
    }
}

.swiper-caption {
    & {
        flex: 1 1 auto;
    }
}

.swiper-button-prev,
.swiper-button-next {
    & {
        background: none;
        border: 0;
        color: $primary;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        padding: 0;
        transition: opacity 0.15s;
        top: 0;
        width: remify(55, 16);
    }

    .swiper-container:hover & {
        opacity: 0.5;
    }

    .swiper-container:hover &:hover,
    &:focus {
        opacity: 1;
    }

    .fa {
        font-size: remify(48, 16);
        left: 50%;
        position: absolute;
        transform: translateX(-50%) translateY(-50%);
        top: 50%;
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    & {
        background: none;
        left: 0;
    }
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    & {
        background: none;
        right: 0;
    }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
    & {
        bottom: auto;
        top: 0;
    }
}

.swiper-pagination-bullet {
    & {
        background: transparentize($dark, 0.5);
        height: remify(10, 16);
        opacity: 1;
        transition: background 0.15s;
        width: remify(10, 16);
    }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    & {
        margin: remify(20 5, 16);
    }
}

.swiper-pagination-bullet-active {
    & {
        background: $primary;
    }
}
