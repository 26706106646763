// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Article
\* ------------------------------------------------------------------------ */

.article {
    & {
		@extend %clearfix;
        display: flex;
        flex-direction: column;
    }
}

.article_image {
    & {
        display: block;
        margin: remify(30 auto 0, 16);
        max-width: 100%;
        order: 999;
    }
}

.article_title {
    & {
        border-bottom: remify(1) solid transparentize($dark, 0.8);
        color: $dark;
        font-size: remify(28.5, 16);
        line-height: remify(27.5, 28.5);
        margin: remify(0 0 12, 28.5);
        padding: remify(0 0 10, 28.5);
    }
}

.article_title_small {
    & {
        color: $foreground_alt;
        display: block;
        font-family: $body-font;
        font-size: remify(14, 28.5);
        font-weight: 400;
        text-transform: none;
    }
}

// excerpt variant

.article.-excerpt {
    &:not(:last-of-type){
        border-bottom: remify(1) solid darken($background_alt, 10);
        margin-bottom: remify(20, 16);
    }

    .article_title {
        font-size: remify(18, 16);
    }

    .article_header > .article_title:not(:last-child) {
        margin-bottom: 0;
    }

    .article_header > .article_title:not(:last-child) + * {
        margin-bottom: remify(10, 16);
    }
}
