// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Hero
\* ------------------------------------------------------------------------ */

.hero-block {
    & {
        @include layer("hero");
    }

    &.-fullbleed {
        background: $background;
    }
}

.hero_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .hero-block.-fullbleed & {
        background: none;
        max-width: 100%;
    }
}

.hero_figure {
    & {
        margin: 0;
        position: relative;
    }
}

.hero_image {
    & {
        display: block;
        height: remify(300);
        left: 50%;
        position: relative;
        transform: translateX(-50%);
    }

    &.-tall {
        height: remify(680);
    }
}

.hero_header {
    & {
        background: transparentize($dark, 0.25);
        bottom: 0;
        left: 0;
        padding: remify(18 20);
        position: absolute;
        right: 0;
        text-align: center;
    }
}

.hero_title {
    & {
        color: $accent_alt;
        font-size: remify(27.5, 16);
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .hero_container.-fullbleed & {
        margin: 0 auto;
        max-width: remify($site-width);
    }
}

.hero_user-content {
    & {
        margin: 0 auto;
        max-width: remify($site-width);
        overflow: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    ol,
    p,
    table,
    ul {
        color: $light;
    }

    ol,
    p,
    table,
    ul {
        font-size: remify(13.5, 16);
    }
}
