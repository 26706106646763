// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Search Form
\* ------------------------------------------------------------------------ */

.search-form_container {
    &.-large {
        font-size: remify(20, 16);
        margin-bottom: remify(20, 20);
    }
    
    &.-collapsible {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;
    }

    &.-collapsible.is-active {
        max-height: remify(100, 16);
    }
}

.search-form {
    & {
        position: relative;
    }

    @include placeholder {
        opacity: 1;
    }
}

.search-form_input {
    & {
        background: none;
        border: 0;
        color: $light;
        font-family: $body-font;
        font-size: remify(16, 16);
        font-style: italic;
        font-weight: 300;
        margin: 0;
        outline: none;
        padding: remify(8 38 8 30, 16);
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &:focus ~ .search-form_background,
    &:hover ~ .search-form_background {
        border-color: $accent_alt;
    }
}

.search-form_button {
    & {
        background: none;
        border: 0;
        bottom: 0;
        color: $light;
        cursor: pointer;
        font-size: remify(16, 16);
        margin: 0;
        outline: none;
        padding: remify(10 18, 16);
        position: absolute;
        right: 0;
        top: 0;
        transition: color 0.15s;
        z-index: 1;
    }

    &:focus,
    &:hover {
        color: $light_alt;
    }

    .fa {
        position: relative;
        z-index: 1;
    }
}

.search-form_button_background {
    & {
        background: $accent_alt;
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewX(-17.5deg);
        top: 0;
        z-index: 0;
    }
}

.search-form_background {
    & {
        background: $accent;
        border: remify(1) solid $accent;
        bottom: 0;
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        transform: skewX(-17.5deg);
        transition: border-color 0.15s;
        top: 0;
        z-index: 0;
    }
}
