// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Content
\* ------------------------------------------------------------------------ */

.content-block {
    & {
        @include layer("content");
        padding: remify(35 25);
    }

    .is-fluidboxactive & {
        position: static;
    }

    &.-fullbleed {
        background: $background;
    }
}

.content_inner {
    & {
        background: $background;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .content-block.-fullbleed > & {
        background: none;
    }
}

// dark

.content-block.-dark {
    &.-fullbleed {
        background: $dark; // non gradient fallback
        background: linear-gradient(to bottom, lighten($dark, 12.9412) 40%, lighten($dark, 5.8824) 60%, #000000 100%);
    }

    .content_inner {
        background: $dark; // non gradient fallback
        background: linear-gradient(to bottom, lighten($dark, 12.9412) 40%, lighten($dark, 5.8824) 60%, #000000 100%);
    }

    &.-fullbleed > .contnet_inner {
        background: none;
    }

    .content_user-content a {
        color: $accent_alt;
    }

    .content_user-content a:focus,
    .content_user-content a:hover {
        color: lighten($accent_alt, 10);
    }

    .content_user-content h1,
    .content_user-content h2,
    .content_user-content ol,
    .content_user-content p,
    .content_user-content table,
    .content_user-content ul {
        color: $light;
    }

    .content_user-content h1,
    .content_user-content h2,
    .content_user-content h3 {
        margin-bottom: remify(10, 16);
    }

    .content_user-content h3,
    .content_user-content h4,
    .content_user-content h5,
    .content_user-content h6 {
        color: $accent_alt;
    }

    .col:not(:last-child) > .content_post {
        padding-bottom: remify(35);
    }

    .content_sidebar {
        background: $accent;
        margin: remify(0 -25 -35);
        padding: remify(35 25);
        width: 100vw;
    }

    .content_sidebar .user-content a {
        color: $light;
    }

    .content_sidebar .user-content a:focus,
    .content_sidebar .user-content a:hover {
        color: $light_alt;
    }

    .content_sidebar .content_user-content h3,
    .content_sidebar .content_user-content h4,
    .content_sidebar .content_user-content h5,
    .content_sidebar .content_user-content h6 {
        color: $light;
    }

    .content_sidebar .content_user-content ul {
        font-size: remify(16, 16);
        list-style: none;
        padding: 0;
    }

    .content_sidebar .content_user-content ul li {
        border-bottom: remify(1) solid transparentize($light, 0.8);
        padding: remify(8 0 6, 16);
    }

    .content_sidebar .content_user-content ul li a {
        display: block;
    }
}
