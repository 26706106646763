@charset "UTF-8";
/* ------------------------------------------------------------------------ * * Helpers
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Colors
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Fonts                                                                    *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Widths                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Break Points                                                             *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Layers                                                                   *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * FontAwesome Icons (http://fortawesome.github.io/Font-Awesome/icons/)     *
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Functions
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Mixins
\* ------------------------------------------------------------------------ */
.article:after, .menu-list_container:after, .menu-list:after {
  content: "" !important;
  clear: both !important;
  display: table !important; }

._bold {
  font-weight: 700 !important; }

._italic {
  font-style: italic !important; }

._block {
  display: block !important; }

._center {
  margin-left: auto !important;
  margin-right: auto !important; }

._left {
  float: left !important; }

._right {
  float: right !important; }

._textcenter {
  text-align: center !important; }

._textleft {
  text-align: left !important; }

._textright {
  text-align: right !important; }

._nomargin {
  margin: 0 !important; }

._nopadding {
  padding: 0 !important; }

._visuallyhidden {
  left: -999em !important;
  left: -100vw !important;
  position: absolute !important;
  top: -999em !important;
  top: -100vh !important; }

._warning {
  color: #9F0000 !important; }

/* visibility classes */
@media screen and (min-width: 48em) {
  ._mobile:not(._tablet):not(._desktop) {
    display: none !important; } }

@media screen and (max-width: 47.9375em) and (min-width: 64em) {
  ._tablet {
    display: none !important; } }

@media screen and (max-width: 47.9375em) {
  ._tablet._desktop:not(._mobile) {
    display: none !important; } }

@media screen and (max-width: 63.9375em) {
  ._desktop:not(._tablet):not(._mobile) {
    display: none !important; } }

@media screen and (min-width: 64em) {
  ._mobile._tablet:not(._desktop) {
    display: none !important; } }

@media screen and (min-width: 48em) and (max-width: 63.9375em) {
  ._mobile._desktop {
    display: none !important; } }

/* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Article
\* ------------------------------------------------------------------------ */
.article {
  display: flex;
  flex-direction: column; }

.article_image {
  display: block;
  margin: 1.875em auto 0em;
  max-width: 100%;
  order: 999; }

.article_title {
  border-bottom: 0.0625rem solid rgba(0, 0, 0, 0.2);
  color: #000000;
  font-size: 1.78125em;
  line-height: 0.96491em;
  margin: 0em 0em 0.42105em;
  padding: 0em 0em 0.35088em; }

.article_title_small {
  color: #696969;
  display: block;
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.49123em;
  font-weight: 400;
  text-transform: none; }

.article.-excerpt:not(:last-of-type) {
  border-bottom: 0.0625rem solid #d5d5d5;
  margin-bottom: 1.25em; }

.article.-excerpt .article_title {
  font-size: 1.125em; }

.article.-excerpt .article_header > .article_title:not(:last-child) {
  margin-bottom: 0; }

.article.-excerpt .article_header > .article_title:not(:last-child) + * {
  margin-bottom: 0.625em; }

/* ------------------------------------------------------------------------ * * Divider
\* ------------------------------------------------------------------------ */
.divider {
  background: #d5d5d5;
  border: 0;
  clear: both;
  height: 0.0625rem;
  margin: 0em 0em 1.25em;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Fluidbox https://github.com/terrymun/Fluidbox
\* ------------------------------------------------------------------------ */
@keyframes fluidboxLoading {
  0% {
    transform: translate(-50%, -50%) rotateX(0) rotateY(0); }
  50% {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0); }
  100% {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(-180deg); } }

.fluidbox {
  outline: none; }

.fluidbox__overlay {
  background-color: rgba(255, 255, 255, 0.85);
  cursor: pointer;
  cursor: zoom-out;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* Transition time for overlay is halved to ensure that flickering doesn't happen */
  transition: all 0.5s ease-in-out; }
  .fluidbox--opened .fluidbox__overlay {
    pointer-events: auto; }

.fluidbox__wrap {
  background-position: center center;
  background-size: cover;
  margin: 0 auto;
  position: relative;
  transition: all 0.5s ease-in-out; }

.fluidbox__thumb {
  transition: opacity 0s ease-in-out 0s;
  /* To prevent flickering, we delay the showing of the image */ }
  .fluidbox--closed .fluidbox__thumb {
    transition: opacity 0s ease-in-out 0s; }

.fluidbox__ghost {
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  transition-duration: 0s, 0.5s;
  transition-delay: 0s;
  -webkit-transition-property: opacity, -webkit-transform;
  /* autoprefixer: off */
  transition-property: opacity, -webkit-transform;
  /* autoprefixer: off */
  transition-property: opacity, transform;
  /* autoprefixer: off */ }
  .fluidbox--opened .fluidbox__ghost {
    cursor: pointer;
    cursor: -webkit-zoom-out;
    cursor: -moz-zoom-out;
    cursor: zoom-out; }
  .fluidbox--closed .fluidbox__ghost {
    transition-delay: 0.5s, 0s; }

.fluidbox__loader {
  opacity: 0;
  perspective: 200px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .fluidbox__loader::before {
    /* You can replace this with any color you want, or even a loading gif if desired */
    background-color: rgba(255, 255, 255, 0.85);
    content: '';
    transform-style: preserve-3d;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    padding-bottom: 20%;
    transform: translate(-50%, -50%);
    transition-property: transform;
    transition-duration: 0.5s;
    transition-delay: 0s; }

.fluidbox--loading .fluidbox__loader {
  opacity: 1;
  transition: opacity 0.5s ease-in-out 0.5s; }
  .fluidbox--loading .fluidbox__loader::before {
    animation: fluidboxLoading 1s 0s infinite ease-in-out forwards; }

.fluidbox__overlay {
  background-color: rgba(0, 0, 0, 0.8); }

/* ------------------------------------------------------------------------ * * Logo
\* ------------------------------------------------------------------------ */
.logo {
  display: block;
  text-decoration: none;
  transition: opacity 0.15s; }

.logo:not(.-nohover):hover {
  opacity: 0.9; }

.logo_image, .custom-logo {
  display: block;
  width: 100%; }

.custom-logo {
  height: auto !important; }

/* ------------------------------------------------------------------------ * * Menu List
\* ------------------------------------------------------------------------ */
.menu-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center; }

.menu-list_item {
  float: left;
  position: relative; }

.menu-list_item.-parent {
  display: flex;
  flex-wrap: wrap; }

.menu-list_item.-mega {
  position: static; }

.menu-list_link {
  display: block;
  flex: 1 1 auto;
  text-decoration: none; }

.menu-list_toggle {
  background: none;
  border: 0;
  cursor: pointer;
  display: block;
  margin: 0; }

.menu-list_toggle > .fa {
  transition: transform 0.15s; }

.menu-list_item.is-active > .menu-list_toggle > .fa {
  transform: rotate(-180deg); }

.menu-list.-center {
  float: right;
  position: relative;
  right: 50%; }

.menu-list.-center > .menu-list_item {
  left: 50%; }

.menu-list.-flex {
  display: flex; }

.menu-list.-flex > .menu-list_item {
  flex: 1 1 auto;
  width: 0; }

.menu-list.-vertical {
  text-align: left; }

.menu-list.-vertical.-right {
  text-align: right; }

.menu-list.-vertical > .menu-list_item {
  float: none; }

.menu-list[class*="-tier"] {
  min-width: 100%; }

.menu-list[class*="-tier"].-accordion {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.25s, visibility 0s 0.25s;
  visibility: hidden;
  width: 100%; }

.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-accordion {
  max-height: 31.25em;
  transition: max-height 0.25s;
  visibility: visible; }

.menu-list[class*="-tier"].-overlay {
  left: -999em;
  min-width: 12.5em;
  opacity: 0;
  position: absolute;
  transition: left 0s 0.15s, opacity 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  top: -999em;
  visibility: hidden; }

.menu-list_item.-parent:hover > .menu-list[class*="-tier"].-overlay,
.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-overlay {
  opacity: 1;
  transition: opacity 0.15s;
  visibility: visible; }

.menu-list[class*="-tier"].-overlay.-reverse {
  transition: opacity 0.15s, right 0s 0.15s, top 0s 0.15s; }

.menu-list_item.-parent:hover > .menu-list[class*="-tier"].-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list[class*="-tier"].-overlay.-reverse {
  left: auto;
  right: 0;
  transition: opacity 0.15s; }

.menu-list_item.-parent:hover > .menu-list.-tier1.-overlay,
.menu-list_item.-parent.is-active > .menu-list.-tier1.-overlay {
  left: 0;
  top: 100%; }

.menu-list.-tier1.-overlay.-reverse {
  left: auto;
  right: 999em; }

.menu-list_item.-parent:hover > .menu-list.-tier1.-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list.-tier1.-overlay.-reverse {
  left: auto;
  right: 0; }

.menu-list_item.-parent:hover > .menu-list.-tier2.-overlay,
.menu-list_item.-parent.is-active > .menu-list.-tier2.-overlay {
  left: 100%;
  top: 0; }

.menu-list.-tier2.-overlay.-reverse {
  left: auto;
  right: 999em; }

.menu-list_item.-parent:hover > .menu-list.-tier2.-overlay.-reverse,
.menu-list_item.-parent.is-active > .menu-list.-tier2.-overlay.-reverse {
  left: auto;
  right: 100%; }

.menu-list_container.-mega {
  display: flex;
  left: -999em;
  opacity: 0;
  position: absolute;
  right: 999em;
  top: -999em;
  transition: left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  visibility: hidden; }

.menu-list_item.-mega.-parent:hover > .menu-list_container.-mega,
.menu-list_item.-mega.-parent.is-active > .menu-list_container.-mega {
  left: 0;
  opacity: 1;
  top: 100%;
  transition: opacity 0.15s;
  right: 0;
  visibility: visible; }

.menu-list_container.-mega > .menu-list.-tier1 {
  flex: 1 1 auto;
  min-width: 0; }

.menu-list.-navigation {
  font-family: "eurostile", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 1.125em;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase; }

.menu-list.-navigation .menu-list_link {
  color: #40550c;
  padding: 0.69444em 1.38889em;
  transition: background-color 0.15s, color 0.15s; }

.menu-list.-navigation .menu-list_link:focus,
.menu-list.-navigation .menu-list_link:hover,
.menu-list.-navigation > .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation > .menu-list_item.-parent.is-active > .menu-list_link {
  color: #719123; }

.menu-list.-navigation .menu-list_toggle {
  color: #40550c;
  font-size: 1em;
  padding: 0.5em 0.75em;
  transition: background-color 0.15s, color 0.15s; }

.menu-list.-navigation .menu-list_toggle:focus,
.menu-list.-navigation .menu-list_toggle:hover,
.menu-list.-navigation .menu-list_item.-parent:hover > .menu-list_toggle,
.menu-list.-navigation .menu-list_item.-parent.is-active > .menu-list_toggle {
  color: #719123; }

.menu-list.-navigation .menu-list_container.-mega,
.menu-list.-navigation .menu-list.-tier1.-accordion,
.menu-list.-navigation .menu-list.-tier1.-overlay {
  background-color: #40550c; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_link {
  color: #ffffff; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier1 .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier1.-overlay .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation .menu-list.-tier1.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
  color: #eeeeee; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle {
  color: #ffffff; }

.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle:focus,
.menu-list.-navigation .menu-list.-tier1 .menu-list_toggle:hover,
.menu-list.-navigation .menu-list.-tier1 .menu-list_item.-parent:hover > .menu-list_toggle,
.menu-list.-navigation .menu-list.-tier1 .menu-list_item.-parent.is-active > .menu-list_toggle {
  color: #eeeeee; }

.menu-list.-navigation .menu-list.-tier2.-accordion,
.menu-list.-navigation .menu-list.-tier2.-overlay {
  background-color: #628212; }

.menu-list.-navigation .menu-list.-tier2 .menu-list_link {
  color: #ffffff; }

.menu-list.-navigation .menu-list.-tier2.-accordion .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier2.-accordion .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_link:focus,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_link:hover,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_item.-parent:hover > .menu-list_link,
.menu-list.-navigation .menu-list.-tier2.-overlay .menu-list_item.-parent.is-active > .menu-list_link {
  color: #eeeeee; }

.menu-list.-navigation .menu-list_container.-mega .menu-list.-tier2 .menu-list_link:before {
  content: "\002D\0020\0020"; }

.menu-list.-meta {
  color: #696969;
  font-family: "eurostile", "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 700;
  text-transform: uppercase; }

.menu-list.-meta .menu-list_item:not(:last-child) {
  margin-right: 1.42857em; }

.menu-list.-meta .menu-list_link {
  color: #696969;
  display: inline-block; }

.menu-list.-meta .menu-list_link:hover {
  color: #838383; }

.menu-list.-pagination {
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.875em;
  font-weight: 400; }

.menu-list.-pagination .menu-list_item:not(:last-child) {
  margin-right: 0.57143em; }

.menu-list.-pagination .menu-list_link.-current {
  color: #000000;
  cursor: auto; }

.menu-list.-sitemap {
  color: #719123;
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 0.9375em;
  font-weight: 400;
  text-align: inherit; }

.menu-list.-sitemap .menu-list_item {
  display: inline;
  float: none; }

.menu-list.-sitemap .menu-list_item:not(:last-child):after {
  content: "\0020\002F\0020"; }

.menu-list.-sitemap .menu-list_link {
  color: #719123;
  display: inline-block; }

.menu-list.-sitemap .menu-list_link:focus,
.menu-list.-sitemap .menu-list_link:hover {
  color: #91ba2d; }

/* ------------------------------------------------------------------------ * * Menu Toggle
\* ------------------------------------------------------------------------ */
.menu-toggle {
  background: none;
  border: 0;
  color: #ffffff;
  font-size: 1em;
  height: 2em;
  margin: 0em 0em 0.625em;
  padding: 0;
  position: relative;
  text-indent: -999em;
  width: 3.125em; }

.menu-toggle:before, .menu-toggle:after {
  background: currentColor;
  bottom: 0;
  content: "\0020";
  display: block;
  height: 0.1875em;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transition: box-shadow 0.15s 0.15s, top 0.15s 0.15s, transform 0.15s;
  width: 1.25em;
  z-index: 1; }

.menu-toggle.-rounded:before, .menu-toggle.-rounded:after {
  border-radius: 0.09375em; }

.menu-toggle.is-active:before, .menu-toggle.is-active:after {
  transition: box-shadow 0.15s, top 0.15s, transform 0.15s 0.15s; }

.menu-toggle:before {
  box-shadow: 0em 0.375em 0em 0em currentColor;
  top: -0.75em; }

.menu-toggle.is-active:before {
  box-shadow: 0 0 0 0 transparent;
  transform: rotate(45deg);
  top: 0; }

.menu-toggle:after {
  top: 0.75em; }

.menu-toggle.is-active:after {
  transform: rotate(-45deg);
  top: 0; }

.menu-toggle_background {
  background: #719123;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skewX(-17.5deg);
  width: 100%;
  z-index: 0; }

.menu-toggle.-search:before {
  content: "";
  font-family: "fontawesome";
  background: none;
  bottom: 0;
  box-shadow: none;
  height: auto;
  left: 50%;
  right: auto;
  position: absolute;
  text-indent: 0;
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  width: auto; }

.menu-toggle.-search:after {
  display: none; }

.menu-toggle.-search .menu-toggle_background {
  background: #40550c; }

/* ------------------------------------------------------------------------ * * Modal
\* ------------------------------------------------------------------------ */
.modal {
  background: #ffffff;
  border: 0.0625rem solid #d5d5d5;
  bottom: 0;
  display: block;
  height: 25em;
  left: 0;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  opacity: 0;
  overflow: auto;
  padding: 1.25em;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.15s, transform 0s 0.15s;
  transform: translateX(-100%);
  visibility: hidden;
  width: 46.875em;
  z-index: 999; }

.modal.is-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.15s;
  visibility: visible; }

@media screen and (min-width: 601px) {
  .admin-bar .modal {
    top: 46px; } }

@media screen and (min-width: 783px) {
  .admin-bar .modal {
    top: 32px; } }

.modal_menu-toggle {
  position: absolute;
  right: 0.75em;
  top: 0.75em; }

/* ------------------------------------------------------------------------ * * Ninja Forms
\* ------------------------------------------------------------------------ */
.nf-form-cont {
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif; }

.nf-form-hp {
  left: -999em;
  position: absolute;
  top: -999em; }

.nf-field-container .pikaday__container {
  display: block; }

.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  list-style: none;
  margin-bottom: 0;
  padding: 0; }

.nf-field-container.label-above .field-wrap,
.nf-field-container.label-right .field-wrap,
.nf-field-container.label-below .field-wrap,
.nf-field-container.label-left .field-wrap {
  align-items: flex-start;
  display: flex; }

.nf-field-container.label-right .field-wrap .nf-field-label,
.nf-field-container.label-left .field-wrap .nf-field-label {
  flex: none; }

.nf-field-container.label-above .field-wrap .nf-field-element,
.nf-field-container.label-below .field-wrap .nf-field-element {
  width: 100%; }

.nf-field-container.label-right .field-wrap .nf-field-element,
.nf-field-container.label-left .field-wrap .nf-field-element {
  flex: 1 1 auto; }

.nf-field-container.label-above .field-wrap {
  flex-direction: column; }

.nf-field-container.label-right .nf-field-label {
  order: 2; }

.nf-field-container.label-right .nf-field-element {
  order: 1; }

.nf-field-container.label-below .field-wrap {
  flex-direction: column; }

.nf-field-container.label-below .nf-field-label {
  order: 2; }

.nf-field-container.label-below .nf-field-element {
  order: 1; }

/* ------------------------------------------------------------------------ * * Opinionated Tweaks
\* ------------------------------------------------------------------------ */
.nf-field-container .list-checkbox-wrap ul,
.nf-field-container .list-radio-wrap ul {
  font-size: 1em;
  margin-bottom: 1.5em; }

.nf-field-container .list-checkbox-wrap ul .text.-label,
.nf-field-container .list-radio-wrap ul .text.-label {
  margin-bottom: 0.25em; }

.nf-field-container .nf-element.-submit {
  background-color: #40550c; }
  .nf-field-container .nf-element.-submit:hover {
    background-color: #719123; }

.nf-field-container .stars {
  margin-bottom: 1.5em; }

.nf-field-container .nf-user-content {
  margin-top: -1.25em; }

.nf-field-container .nf-error-msg {
  font-size: 0.625em;
  margin-bottom: 2.4em;
  margin-top: -2em; }

.nf-field-container .g-recaptcha {
  margin-bottom: 1.5em; }

.nf-field-container.label-above .nf-field-label > .text.-label {
  margin-bottom: 0.25em; }

.nf-field-container.checkbox-container.label-above .checkbox-wrap .text.-label {
  padding-left: 0;
  padding-top: 2em; }

.nf-field-container.checkbox-container.label-above .input.-checkbox + .text.-label.-checkbox:before {
  transform: none;
  top: 0; }

.nf-field-container.label-right .nf-field-label > .text.-label {
  margin-left: 0.625em; }

.nf-field-container.label-below .nf-field-label > .text.-label {
  margin-top: 0.25em; }

.nf-field-container.label-below .nf-field-element .input,
.nf-field-container.label-below .nf-field-element .stars,
.nf-field-container.label-below .list-checkbox-wrap ul,
.nf-field-container.label-below .list-radio-wrap ul {
  margin-bottom: 0;
  order: 1; }

.nf-field-container.checkbox-container.label-below .checkbox-wrap .text.-label {
  padding-left: 0;
  padding-bottom: 2em; }

.nf-field-container.checkbox-container.label-below .input.-checkbox + .text.-label.-checkbox:before {
  transform: none;
  bottom: 0;
  top: auto; }

.nf-field-container.label-left .nf-field-label > .text.-label {
  margin-right: 0.625em; }

.nf-field-container.checkbox-container.label-left .checkbox-wrap .text.-label {
  display: inline-block;
  padding-left: 0;
  padding-right: 2em;
  width: auto; }

.nf-field-container.checkbox-container.label-left .input.-checkbox + .text.-label.-checkbox:before {
  left: auto;
  right: 0; }

.nf-field-container.checkbox-container.label-hidden .input.-checkbox + .text.-label.-checkbox {
  text-indent: -999em; }

.nf-field-container.checkbox-container.label-hidden .input.-checkbox + .text.-label.-checkbox:before {
  text-indent: 0; }

/* ------------------------------------------------------------------------ * * Overlay Closer
\* ------------------------------------------------------------------------ */
.overlay-closer {
  background: rgba(255, 255, 255, 0.85);
  border: 0;
  bottom: 999em;
  content: "\0020";
  cursor: pointer;
  display: block;
  left: -999em;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 999em;
  transition: bottom 0s 0.15s, left 0s 0.15s, opacity 0.15s, right 0s 0.15s, top 0s 0.15s, visibility 0s 0.15s;
  top: -999em;
  visibility: hidden;
  width: 100%;
  z-index: 998; }

.overlay-closer.is-active {
  bottom: 0;
  left: 0;
  opacity: 1;
  right: 0;
  transition: opacity 0.15s;
  top: 0;
  visibility: visible; }

/* ------------------------------------------------------------------------ * * Search Form
\* ------------------------------------------------------------------------ */
.search-form_container.-large {
  font-size: 1.25em;
  margin-bottom: 1em; }

.search-form_container.-collapsible {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s; }

.search-form_container.-collapsible.is-active {
  max-height: 6.25em; }

.search-form {
  position: relative; }

.search-form ::-webkit-input-placeholder {
  opacity: 1; }

.search-form :-moz-placeholder {
  opacity: 1; }

.search-form :-ms-input-placeholder {
  opacity: 1; }

.search-form .placeholder {
  opacity: 1; }

.search-form_input {
  background: none;
  border: 0;
  color: #ffffff;
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif;
  font-size: 1em;
  font-style: italic;
  font-weight: 300;
  margin: 0;
  outline: none;
  padding: 0.5em 2.375em 0.5em 1.875em;
  position: relative;
  width: 100%;
  z-index: 1; }

.search-form_input:focus ~ .search-form_background,
.search-form_input:hover ~ .search-form_background {
  border-color: #719123; }

.search-form_button {
  background: none;
  border: 0;
  bottom: 0;
  color: #ffffff;
  cursor: pointer;
  font-size: 1em;
  margin: 0;
  outline: none;
  padding: 0.625em 1.125em;
  position: absolute;
  right: 0;
  top: 0;
  transition: color 0.15s;
  z-index: 1; }

.search-form_button:focus, .search-form_button:hover {
  color: #eeeeee; }

.search-form_button .fa {
  position: relative;
  z-index: 1; }

.search-form_button_background {
  background: #719123;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewX(-17.5deg);
  top: 0;
  z-index: 0; }

.search-form_background {
  background: #40550c;
  border: 0.0625rem solid #40550c;
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  transform: skewX(-17.5deg);
  transition: border-color 0.15s;
  top: 0;
  z-index: 0; }

/* ------------------------------------------------------------------------ * * Swiper (http://idangero.us/swiper/)
\* ------------------------------------------------------------------------ */
/**
 * Swiper 3.3.1
 * Most modern mobile touch slider and framework with hardware accelerated transitions
 *
 * http://www.idangero.us/swiper/
 *
 * Copyright 2016, Vladimir Kharlampidi
 * The iDangero.us
 * http://www.idangero.us/
 *
 * Licensed under MIT
 *
 * Released on: February 7, 2016
 */
.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  /* Fix of Webkit flickering */
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  -moz-transform: translate3d(0px, 0, 0);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex: 0 0 auto;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative; }

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-transition-property: -webkit-transform, height;
  -moz-transition-property: -moz-transform;
  -o-transition-property: -o-transform;
  -ms-transition-property: -ms-transform;
  transition-property: transform, height; }

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

/* IE10 Windows Phone 8 Fixes */
.swiper-wp8-horizontal {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-wp8-vertical {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

/* Arrows */
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  -moz-background-size: 27px 44px;
  -webkit-background-size: 27px 44px;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

/* Pagination Styles */
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms;
  -moz-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%; }

/* Bullets */
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-white .swiper-pagination-bullet {
  background: #fff; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  -moz-transform: translate3d(0px, -50%, 0);
  -o-transform: translate(0px, -50%);
  -ms-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 5px 0;
  display: block; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 5px; }

/* Progress */
.swiper-pagination-progress {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progress .swiper-pagination-progressbar {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progress .swiper-pagination-progressbar {
  -webkit-transform-origin: right top;
  -moz-transform-origin: right top;
  -ms-transform-origin: right top;
  -o-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progress {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-vertical > .swiper-pagination-progress {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-progress.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.5); }

.swiper-pagination-progress.swiper-pagination-white .swiper-pagination-progressbar {
  background: #fff; }

.swiper-pagination-progress.swiper-pagination-black .swiper-pagination-progressbar {
  background: #000; }

/* 3D Container */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  -moz-perspective: 1200px;
  -o-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(transparent));
  /* Safari 4+, Chrome */
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Chrome 10+, Safari 5.1+, iOS 5+ */
  background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 3.6-15 */
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), transparent);
  /* Opera 11.10-12.00 */
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), transparent);
  /* Firefox 16+, IE10, Opera 12.50+ */ }

/* Coverflow */
.swiper-container-coverflow .swiper-wrapper,
.swiper-container-flip .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px; }

/* Cube + Flip */
.swiper-container-cube,
.swiper-container-flip {
  overflow: visible; }

.swiper-container-cube .swiper-slide,
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-cube .swiper-slide .swiper-slide,
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-flip .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden; }

/* Cube */
.swiper-container-cube .swiper-slide {
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -moz-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

/* Fade */
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -moz-transition-timing-function: ease-out;
  -ms-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -moz-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  -moz-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  -webkit-background-size: 100%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg); } }

.swiper-slide {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  overflow: hidden; }

.swiper-picture,
.swiper-picture {
  flex: none; }

.swiper-caption {
  flex: 1 1 auto; }

.swiper-button-prev,
.swiper-button-next {
  background: none;
  border: 0;
  color: #40550c;
  display: block;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  transition: opacity 0.15s;
  top: 0;
  width: 3.4375em; }

.swiper-container:hover .swiper-button-prev, .swiper-container:hover
.swiper-button-next {
  opacity: 0.5; }

.swiper-container:hover .swiper-button-prev:hover, .swiper-button-prev:focus, .swiper-container:hover
.swiper-button-next:hover,
.swiper-button-next:focus {
  opacity: 1; }

.swiper-button-prev .fa,
.swiper-button-next .fa {
  font-size: 3em;
  left: 50%;
  position: absolute;
  transform: translateX(-50%) translateY(-50%);
  top: 50%; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background: none;
  left: 0; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background: none;
  right: 0; }

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: auto;
  top: 0; }

.swiper-pagination-bullet {
  background: rgba(0, 0, 0, 0.5);
  height: 0.625em;
  opacity: 1;
  transition: background 0.15s;
  width: 0.625em; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 1.25em 0.3125em; }

.swiper-pagination-bullet-active {
  background: #40550c; }

/* ------------------------------------------------------------------------ * * Tooltip
\* ------------------------------------------------------------------------ */
.Zebra_Tooltip .Zebra_Tooltip_Message {
  font-family: "Open Sans", "Arial", "Helvetica", sans-serif; }

.Zebra_Tooltip .Zebra_Tooltip_Message a {
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap; }

/* ------------------------------------------------------------------------ * * Widget
\* ------------------------------------------------------------------------ */
.widget {
  border: 0.0625rem solid #d5d5d5;
  margin: 0em 0em 1.5625em; }

.widget_title {
  background: #272727;
  color: #ffffff;
  font-size: 1.28571em;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0.55556em 0.83333em; }

.widget_content {
  padding: 0.625em 0.9375em; }

.widget_content > :last-child {
  margin-bottom: 0 !important; }

/* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------ * * Page
\* ------------------------------------------------------------------------ */
html,
body {
  height: 100%;
  width: 100%; }

body {
  background: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }

.page_container {
  min-height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%; }

/* ------------------------------------------------------------------------ * * Navigation
\* ------------------------------------------------------------------------ */
.navigation-block {
  position: relative;
  z-index: 4; }

.navigation-block.-fullbleed {
  background: #000000; }

.navigation-block.-flyout {
  background: #000000;
  border-right: 0.0625rem solid #1a1a1a;
  bottom: 999em;
  display: block;
  left: -999em;
  overflow: auto;
  padding: 0;
  position: absolute;
  right: 999em;
  top: -999em;
  transform: translateX(-100%);
  transition: bottom 0s 0.15s, left 0s 0.15s, right 0s 0.15s, top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
  visibility: hidden;
  z-index: 999; }

.navigation-block.-flyout.-right {
  border-left: 0.0625rem solid #1a1a1a;
  border-right: 0;
  left: 999em;
  right: -999em;
  transform: translateX(100%); }

.navigation-block.-flyout.is-active {
  bottom: 0;
  left: 0;
  right: 5rem;
  transition: transform 0.15s;
  transform: translateX(0);
  top: 0;
  visibility: visible; }

.navigation-block.-flyout.-right.is-active {
  left: 5rem;
  right: 0; }

.navigation-block.-pulldown {
  background: #000000;
  border-right: 0.0625rem solid #1a1a1a;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: -999em;
  transform: translateY(-100%);
  transition: top 0s 0.15s, transform 0.15s, visibility 0s 0.15s;
  visibility: hidden;
  width: 100%;
  z-index: 999; }

.navigation-block.-pulldown.is-active {
  top: 0;
  transform: translateY(0);
  transition: transform 0.15s;
  visibility: visible; }

.navigation-block.-sticky {
  position: sticky;
  top: 0;
  width: 100% !important; }

.navigation-block.-sticky.is-sticky {
  border-bottom: 0.0625rem solid #1a1a1a; }

@media screen and (min-width: 601px) {
  .admin-bar .navigation-block.-sticky.is-sticky {
    top: 46px; } }

@media screen and (min-width: 783px) {
  .admin-bar .navigation-block.-sticky.is-sticky {
    top: 32px; } }

.navigation_inner {
  background: #000000;
  margin: 0 auto;
  max-width: 75rem;
  padding: 0rem 0.625rem; }

.navigation-block.-flyout > .navigation_inner,
.navigation-block.-pulldown > .navigation_inner {
  background: none;
  max-width: none;
  padding: 0; }

.navigation-block.-fullbleed > .navigation_inner {
  background: none; }

.navigation_menu-list_container .menu-list.-navigation .menu-list_link {
  color: #ffffff; }

.navigation_menu-list_container .menu-list.-navigation .menu-list_link:focus,
.navigation_menu-list_container .menu-list.-navigation .menu-list_link:hover,
.navigation_menu-list_container .menu-list.-navigation > .menu-list_item.-parent:hover > .menu-list_link,
.navigation_menu-list_container .menu-list.-navigation > .menu-list_item.-parent.is-active > .menu-list_link {
  color: #eeeeee; }

.navigation_menu-list_container .menu-list.-navigation .menu-list_toggle {
  color: #ffffff; }

.navigation_menu-list_container .menu-list.-navigation .menu-list_toggle:focus,
.navigation_menu-list_container .menu-list.-navigation .menu-list_toggle:hover,
.navigation_menu-list_container .menu-list.-navigation .menu-list_item.-parent:hover > .menu-list_toggle,
.navigation_menu-list_container .menu-list.-navigation .menu-list_item.-parent.is-active > .menu-list_toggle {
  color: #eeeeee; }

/* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
.header-block {
  position: relative;
  z-index: 5;
  left: 0;
  padding: 0.78125rem 0rem;
  position: absolute;
  right: 0;
  top: 1.5625rem; }

.header-block.-fullbleed {
  background: #000000;
  background: rgba(0, 0, 0, 0.8);
  border-bottom: 0.1875rem solid #719123; }

.header-block.-fullbleed:after {
  background: #40550c;
  content: "\0020";
  display: block;
  height: 0.5rem;
  left: 0;
  margin-top: 0.1875rem;
  position: absolute;
  right: 0;
  top: 100%; }

.header_inner {
  background: #000000;
  background: rgba(0, 0, 0, 0.8);
  border-bottom: 0.1875rem solid #719123;
  margin: 0 auto;
  max-width: 75rem; }

.header_inner:after {
  background: #40550c;
  content: "\0020";
  display: block;
  height: 0.5rem;
  left: 0;
  margin-top: 0.1875rem;
  position: absolute;
  right: 0;
  top: 100%; }

.header-block.-fullbleed > .header_inner {
  background: none;
  border-bottom: 0; }

.header-block.-fullbleed > .header_inner:after {
  display: none; }

.header_logo {
  max-width: 15rem;
  padding: 0.625rem 1.5625rem; }

.header_menu-toggle {
  display: block; }

.header_menu-toggle .menu-toggle_background {
  width: 125%; }

.header_search-form_container {
  width: 100%; }

.header_search-form_container.-collapsible .search-form {
  margin-bottom: 0.78125em;
  width: auto; }

.header_search-form_container.-collapsible .search-form_button_background {
  width: 125%; }

.header_search-form_container.-collapsible .search-form_background {
  left: -12.5%;
  width: 125%; }

.header_menu-list_container {
  position: relative;
  z-index: 1; }

.header_menu-list_container .menu-list.-navigation {
  margin-bottom: 0.83333em; }

.header_menu-list_container .menu-list.-navigation .menu-list_link {
  color: #ffffff; }

.header_menu-list_container .menu-list.-navigation .menu-list_link:focus,
.header_menu-list_container .menu-list.-navigation .menu-list_link:hover,
.header_menu-list_container .menu-list.-navigation > .menu-list_item.-parent:hover > .menu-list_link,
.header_menu-list_container .menu-list.-navigation > .menu-list_item.-parent.is-active > .menu-list_link {
  color: #eeeeee; }

.header_menu-list_container .menu-list.-navigation .menu-list_toggle {
  color: #ffffff; }

.header_menu-list_container .menu-list.-navigation .menu-list_toggle:focus,
.header_menu-list_container .menu-list.-navigation .menu-list_toggle:hover,
.header_menu-list_container .menu-list.-navigation .menu-list_item.-parent:hover > .menu-list_toggle,
.header_menu-list_container .menu-list.-navigation .menu-list_item.-parent.is-active > .menu-list_toggle {
  color: #eeeeee; }

/* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
.hero-block {
  position: relative;
  z-index: 3; }

.hero-block.-fullbleed {
  background: #ffffff; }

.hero_inner {
  background: #ffffff;
  margin: 0 auto;
  max-width: 75rem; }

.hero-block.-fullbleed .hero_inner {
  background: none;
  max-width: 100%; }

.hero_figure {
  margin: 0;
  position: relative; }

.hero_image {
  display: block;
  height: 18.75rem;
  left: 50%;
  position: relative;
  transform: translateX(-50%); }

.hero_image.-tall {
  height: 42.5rem; }

.hero_header {
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  padding: 1.125rem 1.25rem;
  position: absolute;
  right: 0;
  text-align: center; }

.hero_title {
  color: #719123;
  font-size: 1.71875em;
  margin: 0 auto;
  max-width: 75rem; }

.hero_container.-fullbleed .hero_title {
  margin: 0 auto;
  max-width: 75rem; }

.hero_user-content {
  margin: 0 auto;
  max-width: 75rem;
  overflow: hidden; }

.hero_user-content h1,
.hero_user-content h2,
.hero_user-content h3,
.hero_user-content h4,
.hero_user-content h5,
.hero_user-content h6,
.hero_user-content ol,
.hero_user-content p,
.hero_user-content table,
.hero_user-content ul {
  color: #ffffff; }

.hero_user-content ol,
.hero_user-content p,
.hero_user-content table,
.hero_user-content ul {
  font-size: 0.84375em; }

/* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
.content-block {
  position: relative;
  z-index: 2;
  padding: 2.1875rem 1.5625rem; }

.is-fluidboxactive .content-block {
  position: static; }

.content-block.-fullbleed {
  background: #ffffff; }

.content_inner {
  background: #ffffff;
  margin: 0 auto;
  max-width: 75rem; }

.content-block.-fullbleed > .content_inner {
  background: none; }

.content-block.-dark.-fullbleed {
  background: #000000;
  background: linear-gradient(to bottom, #212121 40%, #0f0f0f 60%, #000000 100%); }

.content-block.-dark .content_inner {
  background: #000000;
  background: linear-gradient(to bottom, #212121 40%, #0f0f0f 60%, #000000 100%); }

.content-block.-dark.-fullbleed > .contnet_inner {
  background: none; }

.content-block.-dark .content_user-content a {
  color: #719123; }

.content-block.-dark .content_user-content a:focus,
.content-block.-dark .content_user-content a:hover {
  color: #91ba2d; }

.content-block.-dark .content_user-content h1,
.content-block.-dark .content_user-content h2,
.content-block.-dark .content_user-content ol,
.content-block.-dark .content_user-content p,
.content-block.-dark .content_user-content table,
.content-block.-dark .content_user-content ul {
  color: #ffffff; }

.content-block.-dark .content_user-content h1,
.content-block.-dark .content_user-content h2,
.content-block.-dark .content_user-content h3 {
  margin-bottom: 0.625em; }

.content-block.-dark .content_user-content h3,
.content-block.-dark .content_user-content h4,
.content-block.-dark .content_user-content h5,
.content-block.-dark .content_user-content h6 {
  color: #719123; }

.content-block.-dark .col:not(:last-child) > .content_post {
  padding-bottom: 2.1875rem; }

.content-block.-dark .content_sidebar {
  background: #40550c;
  margin: 0rem -1.5625rem -2.1875rem;
  padding: 2.1875rem 1.5625rem;
  width: 100vw; }

.content-block.-dark .content_sidebar .user-content a {
  color: #ffffff; }

.content-block.-dark .content_sidebar .user-content a:focus,
.content-block.-dark .content_sidebar .user-content a:hover {
  color: #eeeeee; }

.content-block.-dark .content_sidebar .content_user-content h3,
.content-block.-dark .content_sidebar .content_user-content h4,
.content-block.-dark .content_sidebar .content_user-content h5,
.content-block.-dark .content_sidebar .content_user-content h6 {
  color: #ffffff; }

.content-block.-dark .content_sidebar .content_user-content ul {
  font-size: 1em;
  list-style: none;
  padding: 0; }

.content-block.-dark .content_sidebar .content_user-content ul li {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.2);
  padding: 0.5em 0em 0.375em; }

.content-block.-dark .content_sidebar .content_user-content ul li a {
  display: block; }

/* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
.callout-block {
  position: relative;
  display: block;
  padding: 2.1875rem 1.5625rem; }

.callout-block.-fullbleed {
  background: #40550c;
  border-bottom: 0.1875rem solid #719123;
  border-top: 0.1875rem solid #719123; }

.callout_inner {
  background: #40550c;
  border-bottom: 0.1875rem solid #719123;
  border-top: 0.1875rem solid #719123;
  margin: 0 auto;
  max-width: 75rem; }

.callout-block.-fullbleed > .callout_inner {
  background: none;
  border-bottom: 0;
  border-top: 0; }

.callout_row:last-child > .col:last-child > .callout_button {
  margin-bottom: 0; }

/* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
.footer-block {
  position: relative;
  z-index: 1;
  display: block; }

.footer-block.-fullbleed {
  background: #ffffff; }

.footer_inner {
  background: #ffffff;
  margin: 0 auto;
  max-width: 75rem;
  padding: 1.5625rem; }

.footer-block.-fullbleed > .footer_inner {
  background: none; }

.footer_row.-padded {
  width: calc(100% + 1.25em); }

.footer_row.-padded > .col {
  padding-right: 1.25em; }

.footer_inner > .footer_row > .col:not(:last-child) {
  margin-bottom: 1.25em; }

.footer_inner > .footer_row > .col:last-child {
  text-align: center; }

.footer_link {
  color: #000000; }

.footer_link:focus, .footer_link:hover {
  color: #1a1a1a; }

.footer_text {
  font-size: 0.625em; }

.footer_logo {
  width: 7.5em; }

@media screen and (min-width: 30em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 40em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 48em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Article
\* ------------------------------------------------------------------------ */
  .article {
    display: block; }
  .article_image {
    float: right;
    margin: 0em 0em 3.125em 3.125em;
    width: 30%; }
  .article_image ~ .article_header,
  .article_image ~ .article_content {
    clear: left;
    float: left;
    width: 65%;
    width: calc(70% - 3.125em); }
  .article_title {
    font-size: 2.5em; }
  .article_title_small {
    font-size: 0.45em; }
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Hero
\* ------------------------------------------------------------------------ */
  .hero_image {
    height: 21.875rem; }
  .hero_image.-tall {
    height: 53.125rem; }
  .hero_header {
    padding: 2.1875rem 1.25rem;
    text-align: left; }
  .hero_title {
    font-size: 2.25em; }
  .hero_user-content ol,
  .hero_user-content p,
  .hero_user-content table,
  .hero_user-content ul {
    font-size: 1.0625em; }
  /* ------------------------------------------------------------------------ * * Content
\* ------------------------------------------------------------------------ */
  .content-block.-dark {
    padding: 2.8125rem 1.25rem; }
  .content-block.-dark .col:not(:last-child) > .content_post {
    padding-bottom: 0;
    padding-right: 8.75rem; }
  .content-block.-dark .content_sidebar {
    margin: -2.8125rem -1.25rem -2.8125rem 0rem;
    padding: 2.8125rem 1.25rem 2.8125rem 1.875rem;
    position: relative;
    width: 100%;
    z-index: 2; }
  .content-block.-dark .content_sidebar:before,
  .content-block.-dark .content_sidebar:after {
    background: #40550c;
    bottom: 0;
    content: "\0020";
    display: block;
    position: absolute;
    top: 0;
    z-index: -1; }
  .content-block.-dark .content_sidebar:before {
    background: url("../media/background-content_sidebar_dark.svg") center center/100% 100% no-repeat;
    right: 100%;
    width: 7.5rem; }
  .content-block.-dark .content_sidebar:after {
    left: 0;
    width: 50vw; }
  .content-block.-dark .content_user-content h1,
  .content-block.-dark .content_user-content h2 {
    font-size: 2.125em; }
  .content-block.-dark .content_sidebar .content_user-content ul {
    font-size: 1.25em; }
  /* ------------------------------------------------------------------------ * * Callout
\* ------------------------------------------------------------------------ */
  .callout-block {
    position: relative;
    padding: 0.9375rem 1.5625rem; }
  .callout_row:last-child > .col > .callout_button {
    margin-bottom: 0; }
  /* ------------------------------------------------------------------------ * * Footer
\* ------------------------------------------------------------------------ */
  .footer-block {
    padding: 1.875rem 1.5625rem; }
  .footer_row.-padded {
    width: calc(100% + 3.125em); }
  .footer_row.-padded > .col {
    padding-right: 3.125em; }
  .footer_inner > .footer_row > .col:not(:last-child) {
    margin-bottom: 0; }
  .footer_inner > .footer_row > .col:last-child {
    text-align: right; }
  .footer_text {
    font-size: 0.9375em; }
  .footer_logo {
    width: 15em; } }

@media screen and (min-width: 53.3125em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 60em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */ }

@media screen and (min-width: 64em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header-block {
    padding: 0;
    top: 2.5rem; }
  .header_logo {
    max-width: 16.5625rem;
    padding-right: 0; }
  .header_search-form_container {
    float: right;
    margin-bottom: 2.1875em;
    margin-right: 2.8125em;
    width: 18.75em; }
  .header_search-form_container + * {
    clear: both; } }

@media screen and (min-width: 80em) {
  /* ------------------------------------------------------------------------ * * Module
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Layout
\* ------------------------------------------------------------------------ */
  /* ------------------------------------------------------------------------ * * Header
\* ------------------------------------------------------------------------ */
  .header_logo {
    max-width: 15rem;
    padding-left: 0; } }
