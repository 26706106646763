// Styles written by Jacob Bearce @ Weblinx, Inc.

/* ------------------------------------------------------------------------ *\
 * Callout
\* ------------------------------------------------------------------------ */

.callout-block {
    & {
        @include layer("callout");
        display: block;
        padding: remify(35 25);
    }

    &.-fullbleed {
        background: $accent;
        border-bottom: remify(3) solid $accent_alt;
        border-top: remify(3) solid $accent_alt;
    }
}

.callout_inner {
    & {
        background: $accent;
        border-bottom: remify(3) solid $accent_alt;
        border-top: remify(3) solid $accent_alt;
        margin: 0 auto;
        max-width: remify($site-width);
    }

    .callout-block.-fullbleed > & {
        background: none;
        border-bottom: 0;
        border-top: 0;
    }
}

.callout_row {
    &:last-child > .col:last-child > .callout_button {
        margin-bottom: 0;
    }
}
